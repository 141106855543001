<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :disabled="isNew"
              @click="menu = true"
              :loading="getModalLoading"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field type="text" filled label="Код рахунку" v-model="code"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="code ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <ChartOfAccount :value="parent_id"
                            @autocompleteChange="parentIdChange"
                            label="Підпорядкований рахунку"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва рахунку" v-model="name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-select type="text" filled label="Вид рахунка" v-model="balance_type" :items="balance_types"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="balance_type ? '' : 'req-star'"
                      color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox hide-details label="Кількісний" v-model="counted" class="pa-0 ma-0"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox hide-details label="Позабалансовий" v-model="not_balanced" class="pa-0 ma-0"/>
          </v-col>
          <v-col cols="12">
            <div class="d-flex mb-4">
              <v-btn small depressed color="success" class="mr-2" @click="add_detail">Додати</v-btn>
              <v-btn small depressed color="error" @click="remove_detail">Видалити</v-btn>
            </div>
            <div class="table-header" style="display: flex;">
              <div class="table-col" style="flex: 0 0 60px; padding-left: 5px">
              </div>
              <div class="table-col" style="flex: 0 0 90px;">
                № п/п
              </div>
              <div class="table-col" style="flex: 1;">
                Вид субконто
              </div>
              <div class="table-col" style="flex: 0 0 120px;">
                Тільки обороти
              </div>
            </div>
            <div class="table-body">
              <div class="table-row" style="display: flex"
                   v-for="(item, idx) in details"
                   :key="idx"
              >
                <div class="table-col" style="flex: 0 0 60px; padding-left: 5px">
                  <v-simple-checkbox
                      :value="selected_row === item.row_num"
                      :ripple="false"
                      @input="changeSelectRow(item.row_num)"
                      class="pa-0 ma-0"/>
                </div>
                <div class="table-col font-weight-bold" style="flex: 0 0 90px;">
                  {{ idx + 1 }}
                </div>
                <div class="table-col" style="flex: 1;">
                  <v-select type="text" label="Вид субконто" v-model="item.detail_type" :items="types"
                            hide-details
                            single-line
                            required :rules="[v => !!v || 'Це поле є обов’язковим']"
                            class="mt-0 pt-0"
                            color="grey"/>
                </div>
                <div class="table-col" style="flex: 0 0 120px;">
                  <v-simple-checkbox v-model="item.only_turnover" class="pa-0 ma-0 ml-2 table-input" :ripple="false"/>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1"
             @click="crud_item" class="button-accept"
             :disabled="!isValid.valid"
             :loading="getModalLoading"
      >
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {subconto_types, balance_types} from "@/utils/icons"
import {CREATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
  UPDATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
  REMOVE_CHART_OF_ACCOUNTS_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'chart_of_account_modal_delete'

export default {
  name: "HWP_Chart_Of_Account",
  mixins: [ModalComponentMixin],
  components: {
    ChartOfAccount: () => import("@/components/autocomplite/ChartOfAccount")
  },
  data() {
    return {
      selected_row: -1,
      name: this.item.name,
      code: this.item.code || '',
      parent_id: this.item.parent_id || null,
      counted: this.item.counted || false,
      not_balanced: this.item.not_balanced || false,
      balance_type: this.item.balance_type || null,
      details: this.item.details || [],
      menu: false,
      balance_types: balance_types,
      types: subconto_types
    }
  },
  methods: {
    parentIdChange(payload) {
      this.parent_id = payload === null ? null : payload.value
      if (this.itemId === this.parent_id && this.itemId) {
        this.parent_id = null
        this.$store.commit(ALERT_SHOW, {text: 'Рахунок не може підпорядковуватися сам собі', color: 'error'})
      }
    },
    max_row() {
      let max_row_num = -1

      if (!this.details) {
        return -1
      } else {
        this.details.forEach(item => {
          if ((item.row_num || 0) >= max_row_num) {
            max_row_num = (item.row_num || 0)
          }
        })
        return max_row_num
      }

    },
    add_detail() {
      let max_row_num = this.max_row()
      if (!this.details) this.details = []
      if (this.details.length >= 3) {
        this.$store.commit(ALERT_SHOW, {text: 'Максимальна кількість субконто - 3', color: 'error'})
        return
      }
      this.details.push(
          {
            row_num: max_row_num + 1,
            detail_type: null,
            only_turnover: false,
            system: false,
            order: (this.details.length || 0) + 1
          }
      )
    },
    remove_detail() {
      if (this.selected_row > -1) {
        const row = this.details.find(item => item.row_num === this.selected_row)
        if (row) {
          this.selected_row = -1
          const row_idx = this.details.indexOf(row)
          this.details.splice(row_idx, 1)
        }
      }
    },
    changeSelectRow(row_num) {
      if (this.selected_row === row_num) {
        this.selected_row = -1
      } else {
        this.selected_row = row_num
      }
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.name = this.item.name
      this.code = this.item.code
      this.parent_id = this.item.parent_id
      this.counted = this.item.counted
      this.not_balanced = this.item.not_balanced
      this.balance_type = this.item.balance_type
      this.details = this.item.details || []

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      // this.$emit('closeModal')
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення бухгалтерського рахунку ${this.code}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Код, вид та назва рахунку мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        code: this.code,
        name: this.name,
        parent_id: this.parent_id,
        counted: this.counted,
        not_balanced: this.not_balanced,
        balance_type: this.balance_type,
        details: this.details.map((item, idx) => {
          return Object.assign({}, {
            id: item.id || 0,
            accounting_code_id: item.accounting_code_id || null,
            detail_type: item.detail_type,
            only_turnover: item.only_turnover || false,
            order: item.order ? item.order : idx + 1
          })
        })
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_CHART_OF_ACCOUNTS_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              const details = JSON.parse(JSON.stringify(payload.details || [])).map((i, idx) => {
                return Object.assign({}, i, {row_num: idx + 1})
              })

              this.itemId = payload.id
              this.name = payload.name
              this.code = payload.code
              this.parent_id = payload.parent_id
              this.counted = payload.counted
              this.not_balanced = payload.not_balanced
              this.balance_type = payload.balance_type
              this.details = details
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_CHART_OF_ACCOUNTS_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    isValid() {
      if (!this.code) {
        return {
          text: 'Код рахунку має бути заповнений',
          valid: false
        }
      }
      if (!this.name) {
        return {
          text: 'Назва рахунку має бути заповнений',
          valid: false
        }
      }
      if (!this.balance_type) {
        return {
          text: 'Вид рахунку має бути заповнений',
          valid: false
        }
      }

      const payload = {
        text: '',
        valid: true
      }
      this.details.forEach((item, index) => {
        if (!item.type) {
          payload.text += `Субконто №${index}. Не заповнений видсубконто;`
        }
      })
      return payload
    },
  },
}
</script>

<style scoped lang="scss">
.table-input {
  :deep(input) {
    padding: 2px 8px !important;
    max-height: 26px !important;
  }
}

.table-col {
  padding: 3px 3px;
  font-size: 0.82rem;
}

.table-header {
  .table-col {
    padding: 5px 3px;
    background-color: #e3e3e3;
    font-weight: 500;
    font-size: 0.8rem;
  }
}

.table-body {
  .table-col {
    align-self: center;
  }
}
</style>